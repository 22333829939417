import React from "react"
import { StaticImage } from "gatsby-plugin-image";

const BerlinImages = () => (
  <div>
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_01_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_02_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_03_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_05_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_06_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_07_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_08_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_09_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_10_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_11_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_13_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_14_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_15_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_17_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_18_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_19_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_20_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_21_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_22_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_23_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_24_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_25_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_26_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_27_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_28_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_29_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_30_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_31_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_33_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_34_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_36_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_37_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_38_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_40_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_42_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_44_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_45_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_46_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/Peter Berlin - Permission to Stare - Mariposa Gallery_Page_47_Image_0001.jpg'} alt={'berlin'} />
    <StaticImage className="mb-2" src={'../../images/berlin/z.jpg'} alt={'berlin'} />


  </div>
)


export default BerlinImages;